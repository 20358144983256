<script setup>
  import { ref, defineProps, computed, onMounted } from 'vue'
  import { onClickOutside } from '@vueuse/core'
  import { Search, Link, Close } from '@element-plus/icons-vue'
  import _ from 'lodash'
  import axios from 'axios'
  import 'primevue/resources/themes/mira/theme.css'
  import 'primeicons/primeicons.css'

  const isOpenMobileMenu = ref(false)
  const target = ref(null)
  const activeHeader = ref(null)
  const searchTerm = ref('')
  const notificationRequestIndex = ref(0)
  const userUnopenedNotificationTotalNumber = ref(null)
  const userNotificationTotalNumber = ref(null)
  const userNotificationData = ref([])
  const showAllNotificationsPopup = ref(false)

  onMounted(() => {
    if (props.currentUser) {
      userUnopenedNotificationTotalNumber.value = JSON.parse(
        JSON.stringify(props.unopened_notifications_count)
      )
    }
  })

  async function querySearch(queryString, cb) {
    let fetchProjects = await axios.post('/api/projects', {
      totalCount: 20,
      query: {
        name_cont: queryString
      },
      additionalColumns: ['id', 'name', 'micro_view'],
      sort: 'name ASC'
    })
    cb(fetchProjects.data.data)
  }

  function handleSelect(item) {
    searchTerm.value = item.name
    window.open(item.micro_view, '_blank')
  }

  const props = defineProps({
    currentUser: String,
    headers: Object,
    subheaders: Object,
    unopened_notifications_count: Number
  })

  onClickOutside(target, (event) => (activeHeader.value = null))

  const toggleMobileMenu = () => {
    isOpenMobileMenu.value = !isOpenMobileMenu.value
  }

  function setActiveId(id) {
    activeHeader.value = id
  }

  function checkStringInUrl(searchString) {
    const url = window.location.href
    return url.includes(searchString)
  }

  const headerIdsWithSubheaders = computed(() => {
    const uniqueHeaderIds = new Set(
      props.subheaders.map((subheader) => subheader.header_id)
    )
    return Array.from(uniqueHeaderIds)
  })

  function toggleSearchDiv() {
    const searchBarDiv = document.querySelector('#searchBarDiv')
    const computedStyle = window.getComputedStyle(searchBarDiv)
    let mainHeaderHeight = $('#mainHeader').innerHeight()
    const topValue = computedStyle.getPropertyValue('top')
    searchBarDiv.style.top =
      topValue === '-1000px' ? `${mainHeaderHeight}px` : '-1000px'

    if (topValue === '-1000px') {
      const inputElement = searchBarDiv.querySelector('.el-input__inner')
      if (inputElement) {
        inputElement.focus()
      }
      document.addEventListener('keydown', handleKeyDown)
    } else {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }

  function toggleNotificationDiv() {
    notificationRequestIndex.value = 0
    const notificationDiv = document.querySelector('#notificationDiv')
    const computedStyle = window.getComputedStyle(notificationDiv)
    let mainHeaderHeight = $('#mainHeader').innerHeight()
    notificationDiv.style.top = `${mainHeaderHeight}px`
    const rightValue = computedStyle.getPropertyValue('right')
    notificationDiv.style.right = rightValue === '-1000px' ? `0px` : '-1000px'
    if (userNotificationData.value.length === 0) loadNotifications(0)
  }

  const loadNotifications = async (index) => {
    if (props.currentUser) {
      let notifications = await axios.post('/my_account/notifications', {
        meta: userNotificationTotalNumber.value ? false : true,
        index: index,
        totalCount: 20,
        additionalColumns: [
          'id',
          'description',
          'name',
          'link',
          'opened',
          'created_at'
        ]
      })

      if (!userNotificationTotalNumber.value)
        userNotificationTotalNumber.value =
          notifications.data.meta.recordsFiltered

      let data = deepCopy(notifications.data.data)
      userNotificationData.value.push(...data)
    }
  }

  function handleKeyDown(event) {
    if (event.keyCode === 27) {
      toggleSearchDiv()
    }
  }

  function checkScroll() {
    if (userNotificationData.value.length === userNotificationTotalNumber.value)
      return
    const element = document.getElementById('notificationWrapper')
    if (element.scrollTop + 10 >= element.scrollHeight - element.clientHeight) {
      let index = userNotificationData.value.length
      loadNotifications(index)
    }
  }

  function checkScrollPopup() {
    if (userNotificationData.value.length === userNotificationTotalNumber.value)
      return
    const element = document.getElementById('allNotificationWrapper')
    if (element.scrollTop + 10 >= element.scrollHeight - element.clientHeight) {
      let index = userNotificationData.value.length
      loadNotifications(index)
    }
  }

  async function handleMouseOver(notification, index) {
    if (notification.opened) return
    try {
      userNotificationData.value[index].opened = true
      userUnopenedNotificationTotalNumber.value--
      await axios.put(`/my_account/notifications/${notification.id}`, {
        opened: true
      })
    } catch (e) {
      console.log(e)
      userNotificationData.value[index].opened = false
      userUnopenedNotificationTotalNumber.value++
    }
  }

  function openNotification(notification) {
    window.open(notification.link, '_blank')
  }

  function showAllNotifications() {
    showAllNotificationsPopup.value = true
    toggleNotificationDiv()
  }

  function hideAllNotifications() {
    showAllNotificationsPopup.value = false
  }

  function openNotificationCenter() {
    window.open('/my_account/notifications', '_blank')
  }
</script>

<template>
  <header>
    <nav
      class="flex flex-wrap items-center justify-between w-full md:py-0 px-4 text-lg text-gray-700 bg-white z-50">
      <div>
        <a href="/"><img :src="$headerNavLogo" class="h-6" alt="" /></a>
      </div>

      <svg
        @click="toggleMobileMenu()"
        xmlns="http://www.w3.org/2000/svg"
        id="menu-button"
        class="h-6 w-6 cursor-pointer md:hidden block"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 6h16M4 12h16M4 18h16" />
      </svg>

      <div
        ref="target"
        class="w-full md:flex md:items-center md:w-auto"
        :class="{ hidden: !isOpenMobileMenu }"
        id="menu">
        <ul class="text-base text-gray-700 md:flex md:justify-between md:pt-0">
          <li
            v-for="header in headers"
            :key="header.id"
            class="group items-center"
            @click="setActiveId(header.id)">
            <div class="flex items-center gap-2 md:gap-0">
              <a
                class="md:p-2 py-2 block hover:text-black hover:underline"
                :href="header.path"
                :class="{
                  'text-primary': header.path && checkStringInUrl(header.path)
                }">
                {{ header.name }}
              </a>
              <svg
                v-if="headerIdsWithSubheaders.includes(header.id)"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-3 h-3">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
              </svg>
            </div>
            <ul
              v-if="headerIdsWithSubheaders.includes(header.id)"
              class="flex flex-col md:justify-between ml-4 md:ml-0 md:pt-0 md:absolute min-w-[32px] w-auto bg-white py-2"
              :class="{ hidden: header.id !== activeHeader }">
              <li
                v-for="subheader in subheaders"
                :key="subheader.name"
                class="flex-col">
                <a
                  v-if="subheader?.header_id === header.id"
                  class="md:p-2 py-2 block hover:text-black hover:underline"
                  :href="subheader.path">
                  {{ subheader.name }}
                </a>
              </li>
            </ul>
          </li>

          <div
            v-if="currentUser"
            @click="setActiveId('profile')"
            class="md:flex items-center md:mx-2">
            <div class="flex items-center">
              <img
                id="profile-img"
                class="rounded-full w-6 h-6"
                src="//ssl.gstatic.com/accounts/ui/avatar_2x.png" />
              <div class="ml-2">
                <p class="text-sm font-medium">{{ currentUser }}</p>
                <p class="text-xs text-gray-500"></p>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-3 h-3">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
              </svg>
            </div>
            <div
              class="flex flex-col top-14 min-w-[40px] bg-white md:absolute"
              v-if="activeHeader === 'profile'">
              <a
                class="md:p-2 py-2 block hover:text-black hover:underline"
                href="/my_account">
                My Account
              </a>
              <a
                class="md:p-2 py-2 block hover:text-black hover:underline"
                href="/my_account/notifications">
                Notifications
              </a>
              <a
                class="md:p-2 py-2 block hover:text-black hover:underline"
                href="/users/sign_out"
                data-method="delete">
                Signout
              </a>
            </div>
          </div>

          <div
            @click="toggleSearchDiv"
            v-if="currentUser"
            class="md:flex items-center md:mx-2 cursor-pointer"
            title="Search Microview">
            <el-icon><Search /></el-icon>
          </div>

          <div
            @click="toggleNotificationDiv"
            v-if="currentUser && userUnopenedNotificationTotalNumber > -1"
            class="md:flex items-center md:mx-2 cursor-pointer"
            title="Search Microview">
            <div class="flex flex-wrap justify-content-center gap-4">
              <i
                v-badge="userUnopenedNotificationTotalNumber"
                class="pi pi-bell p-overlay-badge" />
            </div>
          </div>
        </ul>
      </div>
    </nav>
  </header>

  <div class="sidebar-popup" id="searchBarDiv">
    <el-autocomplete
      v-model="searchTerm"
      :fetch-suggestions="querySearch"
      :trigger-on-focus="false"
      placeholder="Search Microview..."
      @select="handleSelect"
      clearable
      :debounce="200">
      <template #default="{ item }">
        <div :title="item.name">
          {{ item.name.substring(0, 20) }}
          {{ item.name.length > 20 ? '...' : '' }}
        </div>
      </template>
    </el-autocomplete>
  </div>

  <div class="notification-extra" id="notificationDiv">
    <div
      class="notification-wrapper"
      id="notificationWrapper"
      style="height: 85%; overflow-y: auto"
      @scroll="checkScroll">
      <div
        v-if="userNotificationData?.length > 0"
        class="notification"
        :style="{
          background: notification.opened ? '#e6f3ff ' : 'white',
          cursor: notification.link ? 'pointer' : 'default'
        }"
        v-for="(notification, index) in userNotificationData">
        <div
          @mouseover="handleMouseOver(notification, index)"
          @click="notification.link ? openNotification(notification) : ''"
          style="
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            gap: 2px;
          ">
          <el-icon v-if="notification.link"><Link /></el-icon>
          {{ notification.name }}
        </div>
        <hr style="width: 100%; margin: 0 auto" />
      </div>
    </div>
    <div class="notifications-btns" style="height: 15%; width: 100%">
      <button
        @click="showAllNotifications"
        style="background: #677785;">
        Expand
      </button>
      <button
        @click="openNotificationCenter"
        style="background: #5c6267;">
        Show All
      </button>
    </div>
  </div>

  <div v-if="showAllNotificationsPopup" class="all-notifications-popup">
    <div class="all-notifications-popup-header">
      <div
        style="
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        ">
        <h1>Notifications</h1>
        <button @click="hideAllNotifications">X</button>
      </div>
      <hr />
    </div>
    <div
      class="notification-wrapper-popup"
      id="allNotificationWrapper"
      @scroll="checkScrollPopup"
      style="height: 400px; overflow-y: auto">
      <div
        v-if="userNotificationData?.length > 0"
        class="notification"
        :style="{
          background: notification.opened ? '#e6f3ff ' : 'white',
          cursor: notification.link ? 'pointer' : 'default'
        }"
        v-for="(notification, index) in userNotificationData">
        <div
          style="
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            gap: 2px;
          "
          @mouseover="handleMouseOver(notification, index)"
          @click="notification.link ? openNotification(notification) : ''">
          <el-icon v-if="notification.link"><Link /></el-icon>
          {{ notification.name }}
        </div>
        <hr style="width: 100%; margin: 0 auto" />
      </div>
    </div>
  </div>
  <div
    v-if="showAllNotificationsPopup"
    class="all-notifications-popup-background"></div>
</template>

<style>
  .notifications-btns {
    height: 15%;
    width: 100%;
  }

  .notifications-btns button {
    height: 100%;
    width: 50%;
    color: white;
  }

  .notifications-btns button:hover {
    opacity: 0.6;
    color: black
  }

  .notification-wrapper::-webkit-scrollbar,
  .notification-wrapper-popup::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .notification-wrapper::-webkit-scrollbar-thumb,
  .notification-wrapper-popup::-webkit-scrollbar-thumb {
    background-color: #666;
    border-radius: 10px;
  }

  .all-notifications-popup {
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);
    z-index: 150;
    background-color: white;
    box-shadow: 0 1px 15px 0 hsla(0, 0%, 48.2%, 0.05);
    width: 30%;
    padding: 8px 16px;
    border-radius: 5px;
  }

  .all-notifications-popup .all-notifications-popup-header {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 8px;
  }
  .all-notifications-popup .all-notifications-popup-header h1 {
    font-size: 30px;
    color: #333;
  }

  .all-notifications-popup .notification-wrapper-popup .notification {
    padding: 8px;
    padding-bottom: 0;
  }

  .all-notifications-popup-background {
    z-index: 149;
    height: 100%;
    width: 100%;
    position: fixed;
    right: 0;
    background: rgb(211 211 211 / 50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fixed-notification-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #f8f9fa;
    padding: 10px;
    border-top: 1px solid #ccc;
  }

  .sidebar-popup {
    position: fixed;
    transition: all 0.8s;
    top: -1000px;
    right: 0;
    border: 1px solid #ccc;
    padding: 12px;
    background: white;
    box-shadow: 0 1px 15px 0 hsla(0, 0%, 48.2%, 0.05);
    border-radius: 10px;
    z-index: 49;
  }

  .notification-extra {
    height: 250px;
    width: 300px;
    overflow-y: auto;
    padding: 0px;

    position: fixed;
    transition: all 0.8s;
    right: -1000px;
    border: 1px solid #ccc;
    background: white;
    box-shadow: 0 1px 15px 0 hsla(0, 0%, 48.2%, 0.05);
    border-radius: 10px;
    z-index: 49;
  }

  .notification-extra .notification div {
    padding: 12px;
  }

  .fixed-notification-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #f8f9fa;
    padding: 10px;
    border-top: 1px solid #ccc;
  }

  .p-overlay-badge .p-badge {
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    background: #5E81AC;
  }
  .p-badge {
    display: flex;
    min-width: 16px;
    height: 16px;
    background: #5E81AC;
  }
</style>
