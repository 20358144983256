import { createApp } from 'vue';
import MainHeader from '../components/layout/MainHeader.vue';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import PrimeVue from 'primevue/config'
import BadgeDirective from 'primevue/badgedirective';

window.mainHeader = function mainHeader(divId, props = null) {
  const app = createApp(MainHeader, props).use(ElementPlus).use(PrimeVue);
  app.directive('badge', BadgeDirective);
  addAssets(app);
  app.mount(`#${divId}`)
}
